function handleErrorMessage(obj) {
    let showErrorMessage = "";
    for (const [key] of Object.entries(obj)) {
        switch (key) {
            case "target":
                showErrorMessage = "目標網址錯誤";
                break;
            case "title":
                showErrorMessage = "名稱錯誤";
                break;
            default:
                showErrorMessage = "";
        }
    }
    return showErrorMessage;
}

export { handleErrorMessage };
